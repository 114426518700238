:root {
  --theme-color: #0c3c4a;
  --highlight-color: #c1b80e;
  --cta-bg-color: #e9de1d;
  --cta-border-color: #999f00;
}

body {
  margin: 0;
  font-family: 'Merriweather',serif;
  font-weight: 400;
  font-size: 1.6rem;
  background-color: #FBFDF2;
}

html { 
  font-size: 62.5%;
  scroll-behavior: smooth;
}

p {
  font-size: 1.6rem;
}

h2 {
  font-size: 3rem;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: top;
}

a, button {
  transition-duration: 0.3s;
  display: inline-block;
  text-decoration: none;
}

a:hover, button:hover {
  opacity: 0.7;
}

._flex {
  display: flex;
  align-items: center;
}

.page-width {
  width: 90%;
  margin: auto;
}

.__cta {
  text-align: center;
  font-weight: 700;
  padding: .5rem 1rem;
  border-radius: 3rem;
  background-color: var(--cta-bg-color);
  color: var(--theme-color);
  border-bottom: 5px solid var(--cta-border-color);
  display: block;
  font-size: 1.8rem;
}

.highlight {
  color: var(--highlight-color);
}

.fadeIn {
  animation: fadeIn 1s ease-out forwards;
}

@keyframes fadeIn {
  from {
      opacity: 0;
      transform: translateY(20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

@media screen and (max-width: 749px) {
  .small-hide {
    display: none;
  }
}