.App {
  width: 100%;
  font-size: 1.6rem;
  padding-top: 7.8rem;
}
@media screen and (max-width: 749px) {
  .App {
    padding-top: 5rem;
  }
}

header {
  display: flex;
  justify-content: space-between;
}

.original__header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  border-bottom: 1px solid #fff;
  background-color: var(--theme-color);
}

.header__menulists {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
}
.header__menulists .__logo {
  max-width: 18rem;
}

.header__menulists .__menu {
  width: 60%;
}

.header__menulists .__cta {
  width: 15rem;
}

.header__menulists h1 {
  margin: 0;
}
.header__menulists .menulist-wrap {
  display: flex;
  list-style: none;
  padding: 1rem;
  justify-content: end;
  align-items: center;
  gap: 3rem;
  margin: 0;
  font-size: 1.6rem;
  color: #fff;
}
@media screen and (max-width: 749px) {
  .header__menulists .menulist-wrap {
    display: none;
  }
}
.header__menulists .menulist__btn {
  width: 100%;
  text-align: center;
}


a {
  cursor: pointer;
}