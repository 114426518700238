.mainvisual {
    overflow: hidden;
  }


  .mainvisual ._contents {
    background: #000;
    height: 80vh;
    color: #fff;
    padding: 5rem;
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  @media screen and (max-width: 749px) {
    .mainvisual ._contents {
      flex-direction: column;
      height: auto;
      padding: 3rem;
    }
  }

  .mainvisual h1,.mainvisual p {
    color:#ffffff
  }

  .mainvisual ._textfield {
    width: 50%;
    z-index: 3;
  }
  .mainvisual ._imagefield {
    width: 50%;
    position: relative;
    z-index: 1;
  }
  @media screen and (max-width: 749px) {
    .mainvisual ._textfield,.mainvisual ._imagefield {
      width: 100%;
      height: 100%;
    }
    .mainvisual ._imagefield {
      order: 1;
      height: 35vh;
    }
    .mainvisual ._textfield {
      order: 2;
    }

  }

  .mainvisual ._imagefield .image_wrap, .mainvisual ._imagefield .image_wrap img {
    border-radius: 50%;
  }
  .mainvisual ._imagefield .image_wrap {
    border: 5px solid #E3FF00;
    position: absolute;
    animation: slideInRight 5s forwards;
  }
 
  .mainvisual ._imagefield .image_wrap._mv1 {
    width: 45rem;
    height: 45rem;
    top: 0;
    left: 0;
    animation-delay: 0s;
  }
  .mainvisual ._imagefield .image_wrap._mv2 {
    width: 30rem;
    height: 30rem;
    bottom: 0;
    right: -5rem;
    animation-delay: 1s;
    opacity: 0;
  }
  @keyframes slideInRight {
    from {
      transform: translateX(100%) rotate(360deg);
      opacity: 0;
    }
    to {
      transform: translateX(0) rotate(0deg);
      opacity: 1;
    }
  }

  @media screen and (max-width: 749px) {

    .mainvisual ._imagefield .image_wrap._mv1 {
      top: -6rem;
      left: 3vw;
    }

    .mainvisual ._imagefield .image_wrap._mv2 {
      width: 20rem;
        height: 20rem;
        bottom: -26rem;
        right: -8rem;
    }
  }


  .mainvisual ._textfield ._inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  
  .mainvisual ._textfield h2 {
    font-size: clamp(4rem, 2.7569rem + 5.3039vw, 7rem);
    font-weight: bold;
    text-shadow: 2px 3px 3px rgba(1, 75, 43, 0.6);
    line-height: 1;
    color: #fff;
  }
  
  
  .mainvisual ._textfield p {
    line-height: 1.2;
    text-shadow: 2px 3px 3px rgba(1, 75, 43, 0.6);
    font-size: 2rem;
  }
  .mainvisual ._textfield a {
    font-size: 2.5rem;
    padding: 1rem 2rem;
    max-width: 30rem;
    margin: 3rem 0;
  }