.stories {
    padding: 5rem 0;
    overflow-x: hidden;
}

.stories ._contents {
    width: 80%;
    margin: auto;
}

@media screen and (max-width: 749px) {
    .stories ._contents {
        width: 90%;
    }
}

.stories ._contents ul {
    list-style: none;
    padding: 0;
}

.stories ._contents ._lists {
    margin-bottom: 5rem;
}
.stories ._contents ._inner {
    display: grid;
    grid-template-columns: repeat(2,1fr);
}

@media screen and (max-width: 749px) {
    .stories ._contents ._inner {
        grid-template-columns: repeat(1,1fr);
    }
}

.stories ._contents ._description, .stories ._contents ._info {
    padding: 3.5rem;
}
@media screen and (max-width: 749px) {
    .stories ._contents ._description, .stories ._contents ._info {
        padding: 2rem;
    }
}

.stories ._contents ._description {
    background-color: #e5db83;
    border-radius: 1rem;
}

.stories ._contents ._info {
    color: var(--theme-color);
}

@media screen and (max-width: 749px) {
    .stories ._contents ._description {
        order: 2;
    }
    
    .stories ._contents ._info {
        order: 1;
    }   
}

.stories ._contents ._subtitle,.stories ._contents h2 {
    text-align: center;
}
.stories ._contents h2 {
    margin-top: 0;
    margin-bottom: 8rem;
    font-size: 5rem;
}

@media screen and (max-width: 749px) {
    .stories ._contents h2 {
        margin-bottom: 3rem;
    }
}
.stories ._contents h3 {
    font-size: 2.3rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--theme-color);
    display: inline-block;
}
.stories ._contents h4 {
    font-size: 2rem;
    margin-bottom: 0;
}

.stories ._contents p {
    margin-top: 0.5rem;
}

.stories ._contents ._items li {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px dashed #dedede;
}

.stories ._contents ._items li svg {
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
}

.stories ._contents ._items li span {
    font-size: 2rem;
}