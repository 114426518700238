.original-footer{
    background-color:var(--theme-color);
    padding: 2rem;
}

.original-footer p {
    text-align: center;
    color:#ffffff;
    font-size: 1rem;
  }
.policy_menus {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.policy_menus a {
    color: #ffffff;
    font-size: 1.4rem;
}