.body03 {
    padding: 5rem 0;
    overflow-x: hidden;
    background-color: #FBFDF2;
  }
  .body03 ._inner {
    max-width: 1000px;
    width: calc(100% - 40px);
    margin-left: auto;
    margin-right: auto;
  }
  .body03 h2, .body03 h5, .body03 p {
    margin: 0;
    line-height: 1.4;
  }
  .body03 ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .body03 ._grid {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  @media screen and (max-width: 749px) {
    .body03 ._grid {
      flex-wrap: wrap;
    }
  }
  .body03 .img-feild {
    position: relative;
    width: 90rem;
    margin-right: -108px;
    flex: 0 0 auto;
    height: 40rem;
  }
  @media screen and (max-width: 749px) {
    .body03 .img-feild {
      width: 50rem;
      height: 30rem;
      margin-right: -8px;
    }
  }
  .body03 ._list {
    margin-bottom: 3rem;
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }
  .body03 .img_inner {
    position: absolute;
    top: 0;
    left: 50%;
    width: 87%;
    height: 100%;
    background-color: transparent;
    transform: translateX(-50%) skewX(-18deg);
    pointer-events: none;
  }
  @media screen and (max-width: 749px) {
    .body03 .img_inner {
      transform: translateX(-50%) skewX(-10deg);
    }
  }
  .body03 ._txtfeild {
    border-top: 5px solid var(--theme-color);
    padding-top: 5rem;
    padding-bottom: 9rem;
    width: 60rem;
    flex: 0 0 auto;
    z-index: 1;
    background-color: #fff;
    transform: translateX(0) skewX(-18deg);
    opacity: 1;
    transition: transform 1s, opacity 1s;
    box-shadow: 10px 10px 33px -9px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 10px 10px 33px -9px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 33px -9px rgba(0, 0, 0, 0.75);
  }
  @media screen and (max-width: 749px) {
    .body03 ._txtfeild {
      width: 100%;
      transform: translateX(0) skewX(0);
      margin-top: -5rem;
    }
  }
  
  .body03 ._txtfeild-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin: auto;
    transform: skewX(18deg);
    gap: 1rem;
  }
  @media screen and (max-width: 749px) {
    .body03 ._txtfeild-inner {
      transform: skewX(0);
    }
  }
  .body03 h2, .body03 h5 {
    font-weight: bold;
  }
  .body03 h5 {
    font-size: 2rem;
  }
  .body03 h2 {
    font-size: 4.5rem;
    line-height: 1;
  }
  .body03 .copy_wrap {
    border: 1px solid var(--theme-color); 
    color:var(--theme-color); 
    position: absolute;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -26px;
    bottom: 30px;
    min-width: 375px;
    height: 37px;
    transform: translate(0) skew(-5deg);
  }
  @media screen and (max-width: 749px) {
    .body03 .copy_wrap {
      transform: skew(0);
    }
  }
  .body03 .copy_wrap p {
    transform: skewX(18deg);
    font-weight: bold;
    font-size: 2rem;
  }
  @media screen and (max-width: 749px) {
    .body03 .copy_wrap p {
      transform: skewX(0);
    }
  }
  .body03 .img_wrap {
    height: 40rem;
    background-color: #000;
    overflow: hidden;
  }
  @media screen and (max-width: 749px) {
    .body03 .img_wrap {
      height: 30rem;
    }
  }
  .body03 .img_wrap img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: transform 1s ease;
  }
/* 
  @keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeIn {
  animation: fadeIn 1s ease-out forwards;
} */
