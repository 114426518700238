.whatcanido {
    padding: 5rem;
}

@media screen and (max-width: 749px) {
    .whatcanido {
        padding: 1rem;
    }
}

.whatcanido ._contents {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    align-items: center;
}

@media screen and (max-width: 749px) {
    .whatcanido ._contents {
        grid-template-columns: repeat(1,1fr);
    }
}

.whatcanido .grid-item {
    display: flex;
    flex-direction: column;
}

.whatcanido h2 {
    font-size: 7rem;
    text-align: center;
}

@media screen and (max-width: 749px) {
    .whatcanido h2 {
        font-size: 5rem;
    } 
}

.whatcanido ._details {
    padding: 0 4rem;
    border-left: 1px solid #dedede;
}

@media screen and (max-width: 749px) {
    .whatcanido ._details {
        padding: 2rem;
        border-left: none;
        text-align: center;
    }
}

.whatcanido ._details h3 {
    margin: 0;
    font-size: 2.5rem;
}
.whatcanido ._details ul {
    list-style: none;
    padding: 0;
    margin-top: 0.5rem;
    margin-bottom: 5rem;
}